import React, { Component } from "react"
import { graphql } from 'gatsby'
import PropTypes from "prop-types"


class PostTemplate extends Component {
    render() {
        const post = this.props.data.wordpressPost

        
        return (
            <div>
                <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
                <div dangerouslySetInnerHTML={{ __html: post.content }} />
                {post != null
                    && post.acf != null
                    && post.acf.product_title != null
                    ?  <div dangerouslySetInnerHTML={{ __html: post.acf.product_title }} />
                    : null
                }
            </div>
        )
    }
}


export default PostTemplate

export const pageQuery = graphql`
    query currentPostQuery($id: String!) {
        wordpressPost(id: { eq: $id }) {
            title
            content
        }
    }
`